export const REACT_APP_API_KEY: string = process.env.REACT_APP_API_KEY || ''
export const REACT_APP_API_URL: string = process.env.REACT_APP_API_URL || ''
export const REACT_APP_API_URL_V2: string = process.env.REACT_APP_API_URL_V2 || ''
export const REACT_APP_AUTOCOMPLETE: string = process.env.REACT_APP_AUTOCOMPLETE || ''
export const PUBLIC_URL: string = process.env.PUBLIC_URL || ''
export const REACT_APP_STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
export const REACT_APP_API_DOMAIN_URL: string = process.env.REACT_APP_API_DOMAIN_URL || ''
export const REACT_APP_GOOGLE_REVIEW_LINK: string = process.env.REACT_APP_GOOGLE_REVIEW_LINK || ''
export const REACT_APP_GOOGLE_REVIEW_LINK_NEW: string = process.env.REACT_APP_GOOGLE_REVIEW_LINK_NEW || ''
export const REACT_APP_GOOGLE_TAG_MANAGER: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER || ''
